import $ from 'jquery'
import { blockScroll, unblockScroll } from "./utils";

const $doc = $(document)
const $mobileNav = $('.js-mobile-nav')
const $hamburger = $('.js-hamburger')

function showMobileNav(event) {
  event.preventDefault()
  $mobileNav.toggleClass('is-active')
  $hamburger.toggleClass('is-active')
  if ( $mobileNav.hasClass('is-active')) {
    $hamburger.attr('aria-expanded', 'true')
    blockScroll()
  } else {
    $hamburger.attr('aria-expanded', 'false')
    unblockScroll()
  }
}

function initMobileDropdowns() {
  const submenu = $(this).children('.sub-menu');
  submenu.slideToggle(500);
  $('.sub-menu').not(submenu).slideUp(500);
}

export default function initMobileNav(){
  $doc.on('click', '.js-hamburger', showMobileNav )
  $doc.on('keydown', function(event) {
    if ( $mobileNav.hasClass('is-active') && (event.key == "Escape") ) {
      $hamburger.attr('aria-expanded', 'false')
      $mobileNav.toggleClass('is-active')
      $hamburger.toggleClass('is-active')
    }
  })
  $doc.on('focusin', function (event) {
    var $target = $(event.target);
    if (!$target.closest('.js-header-wrapper').length) {
      if ( $mobileNav.hasClass('is-active') ) {
        $hamburger.attr('aria-expanded', 'false')
        $mobileNav.toggleClass('is-active')
        $hamburger.toggleClass('is-active')
      }
    }
  })
  $doc.on('click', '.js-mobile-nav .menu-item-has-children', initMobileDropdowns )
}
