import $ from 'jquery'

const $doc = $(document)

var scrollHandling = {
  allow: true,
  reallow: function() {
    scrollHandling.allow = true;
  },
  delay: 800, //(milliseconds) adjust to the highest acceptable value
  disallow: false
};

function loadmore() {
  const button = $('.js-loadmore')

  if(button.length) {

    const wrapper = button.closest('section').find('.wrapper')
    const loader = wrapper.find('.loader')
    const list = wrapper.find('ul')
    let curr_page = 2
    let loading = false

    $(window).scroll(function(){
      if( !loading && scrollHandling.allow && !scrollHandling.disallow) {
        scrollHandling.allow = false;
        setTimeout(scrollHandling.reallow, scrollHandling.delay);

        var offset = $(button).offset().top - $(window).scrollTop();
        
        if( 1000 > offset ) {
          loading = true;
          loader.addClass('loading');
          
          $.ajax({
            url : ajax_object.ajax_url,
            type : 'POST',
            data : {
              curr_page,
              action:'loadmore'
            },
            success : function( res ){
              if (res.length) {
                list.append(res)
                loader.removeClass('loading');
                curr_page = curr_page + 1;
                loading = false; 
              } else {
                scrollHandling.disallow = true;
              }
            },
            error: function(xhr) {
              console.log('Error');
            }
          })
        }
      }
    })

  }
}

export default function initLoadmore(){
  $doc.on('ready', loadmore )
}
