import Headroom from 'headroom.js'

export default function initHeadroom(){
  const theHeader = document.querySelector('.js-header')
  const headroom = new Headroom(theHeader, { 
    offset: {
        up: 100,
        down: 50
    }, 
    tolerance : {
        up : 30,
        down : 5
    },
  })
  headroom.init()
}