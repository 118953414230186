/*
A debounce function is a method for preventing a quick
series of events from repeatedly activating a function.
It works by postponing function execution until a certain period
has passed without the event being fired.
@see https://www.paulsblog.dev/advanced-javascript-functions-to-improve-code-quality/#debounce
*/
export function debounce(func, delay) {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), delay);
  };
}

/*
The Throttle function is similar to the Debounce function but
with slightly different behavior. Instead of limiting the rate
at which the function is called, the Throttle function limits
the rate at which the function is executed.
@see https://www.paulsblog.dev/advanced-javascript-functions-to-improve-code-quality/#throttle
 */
export function throttle(func, delay) {
  let wait = false;

  return (...args) => {
    if (wait) {
      return;
    }

    func(...args);
    wait = true;
    setTimeout(() => {
      wait = false;
    }, delay);
  }
}

/*
The Once function is a method that will prevent executing if already called.
@see https://www.paulsblog.dev/advanced-javascript-functions-to-improve-code-quality/#once
 */
export function once(func) {
  let ran = false;
  let result;
  return function () {
    if (ran) return result;
    result = func.apply(this, arguments);
    ran = true;
    return result;
  };
}

/*
Memoize is a JavaScript function, that is used to cache the results of a
given function to prevent calling computationally expensive routines
multiple times with the same arguments.
@see https://www.paulsblog.dev/advanced-javascript-functions-to-improve-code-quality/#memoize
*/
export function memoize(func) {
  const cache = new Map();
  return function() {
    const key = JSON.stringify(arguments);
    if (cache.has(key)) {
      return cache.get(key);
    }
    const result = func.apply(this, arguments);
    cache.set(key, result);
    return result;
  };
}

/*
The Curry function (also known as Currying) is an advanced JavaScript
function used to create a new function from an existing one by
"pre-filling" some of its arguments.
@see https://www.paulsblog.dev/advanced-javascript-functions-to-improve-code-quality/#curry
*/
export function curry(func, arity = func.length) {
  return function curried(...args) {
    if (args.length >= arity) return func(...args);
    return function(...moreArgs) {
      return curried(...args, ...moreArgs);
    };
  };
}

/*
The Partial function in JavaScript is similar to the Curry function.
The significant difference between Curry and Partial is that a call
to a Partial function returns the result instantly instead of
returning another function down the currying chain.
@see https://www.paulsblog.dev/advanced-javascript-functions-to-improve-code-quality/#partial
*/
export function partial(func, ...args) {
  return function partiallyApplied(...moreArgs) {
    return func(...args, ...moreArgs);
  }
}

/*
The Pipe function is a utility function used to chain multiple
functions and pass the output of one to the next one in the chain.
@see https://www.paulsblog.dev/advanced-javascript-functions-to-improve-code-quality/#pipe
*/
export function pipe(...funcs) {
  return function piped(...args) {
    return funcs.reduce((result, func) => [func.call(this, ...result)], args)[0];
  };
}

/*
The Compose function is the same as the Pipe function,
but it will use reduceRight to apply all functions.
@see https://www.paulsblog.dev/advanced-javascript-functions-to-improve-code-quality/#compose
*/
export function compose(...funcs) {
  return function composed(...args) {
    return funcs.reduceRight((result, func) => [func.call(this, ...result)], args)[0];
  };
}

/*
The Pick function in JavaScript is used to select specific
values from an object. It is a way to create a new object
by selecting certain properties from a provided project.
@see https://www.paulsblog.dev/advanced-javascript-functions-to-improve-code-quality/#pick
*/
export function pick(obj, keys) {
  return keys.reduce((acc, key) => {
    if (obj.hasOwnProperty(key)) {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
}

/*
The Omit function is the opposite of the Pick function,
as it will remove certain properties from an existing object.
@see https://www.paulsblog.dev/advanced-javascript-functions-to-improve-code-quality/#omit
*/
export function omit(obj, keys) {
  return Object.keys(obj)
    .filter(key => !keys.includes(key))
    .reduce((acc, key) => {
      acc[key] = obj[key];
      return acc;
    }, {});
}

/*
The Zip function is a JavaScript function that matches each passed
array of elements to another array element and is used to combine
multiple arrays into a single array of tuples.
@see https://www.paulsblog.dev/advanced-javascript-functions-to-improve-code-quality/#zip
*/
export function zip(...arrays) {
  const maxLength = Math.max(...arrays.map(array => array.length));
  return Array.from({ length: maxLength }).map((_, i) => {
    return Array.from({ length: arrays.length }, (_, j) => arrays[j][i]);
  });
}

/*
Scroll to an element with a smooth animation.
 */
export function scrollToElement(element, duration = 200, offset = 0) {
  const start = window.pageYOffset;
  const to = element.getBoundingClientRect().top + start - offset;
  const change = to - start;
  let currentTime = 0;
  const increment = 20;

  const animateScroll = () => {
    currentTime += increment;
    const val = Math.easeInOutQuad(currentTime, start, change, duration);
    window.scrollTo(0, val);
    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    }
  };

  animateScroll();
}

/*
Block scrolling on the body element.
 */
export function blockScroll() {
  document.documentElement.style.overflow = 'hidden';
}

/*
Unblock scrolling on the body element.
 */
export function unblockScroll() {
  document.documentElement.style.overflow = '';
}
